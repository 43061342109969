/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #000000;
  --ion-color-primary-rgb: 0, 0, 0;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #000000;
  --ion-color-primary-tint: #1a1a1a;

  --ion-color-secondary: #319ec9;
  --ion-color-secondary-rgb: 49, 158, 201;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #2b8bb1;
  --ion-color-secondary-tint: #46a8ce;

  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  --ion-color-success: #19a560;
  --ion-color-success-rgb: 25, 165, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #169154;
  --ion-color-success-tint: #30ae70;

  --ion-color-warning: #ebb239;
  --ion-color-warning-rgb: 235, 178, 57;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #cf9d32;
  --ion-color-warning-tint: #edba4d;

  --ion-color-danger: #ff3e3e;
  --ion-color-danger-rgb: 255, 62, 62;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e03737;
  --ion-color-danger-tint: #ff5151;

  --ion-color-dark: #303133;
  --ion-color-dark-rgb: 48, 49, 51;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #2a2b2d;
  --ion-color-dark-tint: #454647;

  --ion-color-medium: #a8a8a8;
  --ion-color-medium-rgb: 168, 168, 168;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #949494;
  --ion-color-medium-tint: #b1b1b1;

  --ion-color-light: #ececec;
  --ion-color-light-rgb: 236, 236, 236;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d0d0d0;
  --ion-color-light-tint: #eeeeee;

  //CMS received colors

  --cms-button-text-color: #ffffff;
  --cms-button-bg-color: #000000;
  --cms-button-bg-color-hover: var(--ion-color-medium);
  --cms-button-bg-color-pressed: var(--ion-color-medium);
  --cms-link-text-color: var(--ion-color-medium);
  --cms-error-text-color: var(--ion-color-danger);
  --cms-error-bg-color: var(--ion-color-danger);
  --cms-intro-bg-image: 'src()';
  --cms-intro-bg-color: #ececec;
  --cms-header-logo: 'src()';
  --cms-header-text-color: var(--ion-color-dark);
  --cms-header-bg-color: var(--ion-color-light);
  --cms-header-icon-color: var(--ion-color-dark);
  --cms-menu-bg-color: var(--ion-color-light);
  --cms-menu-bg-image: 'src()';
  --cms-menu-text-color: var(--ion-color-dark);
  --cms-menu-icon-color: var(--ion-color-dark);
  --cms-menu-hover-color: var(--ion-color-secondary);
  --cms-tabs-bg-color: var(--ion-color-light);
  --cms-tabs-text-color: var(--ion-color-dark);
  --cms-tabs-icon-color: var(--ion-color-dark);
  --cms-component-header-text-color: var(--ion-color-light);
  --cms-component-header-bg-color: var(--ion-color-dark);
  --cms-component-header-icon-color: var(--ion-color-light);
  --cms-web-bg-color: var(--ion-color-light);
  
  --font-bold: 'poppinssemibold';
  --font-regular: 'poppinsregular';
  --font-light: 'poppinslight';
  --font-icon: 'Empatica-icons';

  --small-item-padding: 10px 16px;
  --big-item-padding: 16px 16px;
  --font-family-base: 'poppinsregular';

  //Ionic variables overrides
  --highlight-background: var(--ion-color-danger) !important;
}

$app-direction: ltr;
