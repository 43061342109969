.chat-component {
  margin-bottom: 10px;

  &.list-view {
    ion-item.item {
      padding: var(--small-item-padding);
      border-bottom: 1px solid var(--ion-color-light);

      .item-inner {
        padding-right: 0px;
      }

      p {
        color: var(--ion-color-medium);
      }

      small {
      }
    }
  }
}

.chat {
  //height: 100%;

  .chat-row {
    padding: 0px;
    margin-bottom: 10px;

    .chat-item {
      &.chat-item-date {
        background-color: var(--ion-color-light);
        color: var(--ion-color-dark);
        font-family: var(--font-regular);
        border-radius: 5px;
        text-align: center;
        width: auto;
        padding: 5px 10px;
        margin-top: 25px;
        margin-bottom: 30px;
        transition: all ease 0.5s;
      }

      &.chat-item-alert {
        background-color: var(--ion-color-primary);
        color: #fff;
        font-family: var(--font-regular);
        border-radius: 20px;
        width: auto;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        transition: all ease 0.5s;

        p {
          margin-bottom: 0px;
        }

        i {
          font-size: 1.8rem;
          margin-right: 10px;
        }
      }

      &.chat-item-message {
        .chat-avatar {
          flex: 1 0;
          order: 1;
          min-width: 45px;
          transition: all ease 0.5s;
          padding: 0 0 0 0;

          .circle-pic {
            width: 10vw;
            height: 10vw;
            min-width: 10vw;
            min-height: 10vw;
            max-width: 250px;
            max-height: 250px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
            background-color: var(--ion-color-light);

            .plt-desktop & {
              width: 65px;
              height: 65px;
              min-width: 65px;
              min-height: 65px;
              max-width: 65px;
              max-height: 65px;
            }
          }

          &.comment {
            max-height: 45px;
            margin-right: 5px;
            max-width: 45px;
          }
        }

        .chat-bubble {
          background-color: var(--ion-color-light);
          padding: 6px 15px 4px;
          border-radius: 10px;
          flex: 7 0;
          order: 2;
          transition: all ease 0.5s;
          overflow: hidden;
          white-space: normal;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin: 0 5px;

          .user-name {
            color: var(--ion-color-dark);
            margin: 0px;
            font-size: 1.2rem;
          }

          .bubble-inner {
            font-size: 1.2rem;
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: flex-start;
            padding: 3px 0px;

            .message {
              width: 100%;
              color: var(--ion-color-dark);
              font-family: var(--font-regular);
              transition: all ease 0.5s;
              margin: 0px;
              display: inline-block;
              flex: 5 2 80%;
              line-height: 1.1;
              padding-right: 5px;
              text-align: end;

              .message-content {
              }
            }

            .img-message {
              transition: all ease 0.5s;
              margin: 0px;
              display: inline-block;
              flex: 6 0 100%;
              min-height: 200px;
              max-height: 200px;
              width: 100%;
              object-fit: cover;

              .plt-desktop & {
                min-height: 400px;
                max-height: 400px;
              }
            }
            .message-footer {
              color: var(--ion-color-medium);
              text-align: right;
              font-size: 0.9rem;
              transition: all ease 0.5s;
              flex: 1 0 20%;
              float: right;
              display: inline-flex;
              position: relative;
              padding-right: 0px;
              align-items: center;
              justify-content: space-between;
              align-self: flex-end;

              &.date {
                font-size: 12px;
              }
              span {
                width: 100%;
                text-align: right;
              }
            }
          }
        }
      }
    }

    &.mine {
      & + .chat-row {
        margin-top: 0px;
      }

      & + .chat-row.mine {
        margin-top: 0px;
      }

      .chat-item {
        .chat-avatar {
          order: 2;
          text-align: right;
        }

        .chat-bubble {
          order: 1;
          background-color: var(--ion-color-primary);
          .user-name {
            color: #fff;
          }

          .bubble-inner {
            .message {
              text-align: start;
              color: #fff;
              a {
                color: #fff;
              }
            }

            .message-footer {
              color: #fff;
              opacity: 0.6;
            }
          }
        }
      }
    }

    & + .chat-row {
      margin-top: 0px;
    }

    & + .chat-row.mine {
      margin-top: 0px;
    }

    &.same-as-prev {
      padding-top: 0px !important;

      .chat-avatar {
        opacity: 0;
      }

      .chat-item {
        .chat-bubble {
          .user-name {
            display: none;
          }
        }
      }
    }

    &.middle {
      margin-bottom: 2px;
      .chat-item {
        .chat-bubble {
        }
      }

      &.same-as-prev {
      }
    }
  }
}

.chat-footer {
  ion-toolbar {
    padding: 0px 12px 0px 12px !important;
    --background: white;

    .send-button {
      display: inline-flex;
      padding: 0;
    }

    ion-textarea {
      max-height: 100px;
      margin-bottom: 0px !important;
      margin-top: 0px !important;
      background: #fff;
      border-radius: 5px;
      padding: 0px;
      overflow: hidden;
      transition: all ease 0.5s;

      textarea {
        margin: 0px 8px 0px 0px;
        --padding-start: 12px;
        --padding-end: 12px;
        --padding-top: 12px;
        --padding-bottom: 12px;
        width: 100%;
        max-width: 100%;
        max-height: 40px;
        height: auto;
        resize: none;
        transition: all ease 0.5s;
      }
    }

    ion-button {
      --padding-start: 0px;
      --padding-end: 0px;
      height: 30px;
      padding: 0 0em;
      align-self: center;
      margin: 0px;
      i {
        font-size: 2.2rem;
      }

      img {
        width: 28px;
        height: 28px;
      }
    }
  }
  // .custom-icon {
  //   display: block;
  //   margin-left: auto;
  //   margin-right: auto;
  //   width: 50%;
  // }
}
