h1 {
  font-size: 1.8rem !important;
  font-family: var(--font-bold);
  line-height: 1.2;
  font-weight: normal;
}

h2 {
  font-size: 1.4rem !important;
  font-family: var(--font-bold);
  line-height: 1.2;
  font-weight: normal;
}

h3 {
  font-size: 1.2rem !important;
  line-height: 1.2;
  font-weight: normal;
}

p {
  font-size: 0.9rem !important;
  line-height: 1.4 !important;
  font-weight: normal !important;
}

small {
  font-size: 0.8rem;
  line-height: 1.2;
  font-weight: normal;
}
