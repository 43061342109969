/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

// http://ionicframework.com/docs/theming/

// App Global Sass
// --------------------------------------------------
// Put style rules here that you want to apply globally. These
// styles are for the entire app and not just one component.
// Additionally, this file can be also used as an entry point
// to import other Sass files to be included in the output CSS.
//
// Shared Sass variables, which can be used to adjust Ionic's
// default Sass variables, belong in "theme/variables.scss".
//
// To declare rules for a specific mode, create a child rule
// for the .md, .ios, or .wp mode classes. The mode class is
// automatically applied to the <body> element in the app.

@import 'theme/fonts';
@import 'theme/text';
@import 'theme/items';
@import 'theme/buttons';
@import 'theme/menu';
@import 'theme/icons';
@import 'theme/header';
@import 'theme/alerts';

@import 'theme/calendar';
@import 'theme/chat';

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

html.md,
html.ios {
  font-family: var(--font-regular);
  --ion-default-font: var(--font-regular);
  font-size: 16px;
}

.only-desktop {
  display: none;
}

.only-mobile {
  display: inherit;
}

ion-content.content {
  color: var(--ion-color-dark);

  &[login-content] {
    background-color: var(--ion-color-light);
    background-size: cover;
    background-position: top center;

    .scroll-content {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.contact-header {
  position: relative;
  .header-bg {
    background-color: var(--cms-header-bg-color);
    height: 50%;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }

  ion-avatar {
    margin-top: 1em;
    width: 120px;
    height: 120px;
    background-color: var(--ion-color-light) !important;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    z-index: 10;
  }
}

.contact-data-list {
  ion-item.item {
    --padding-start: 0px;
    --padding-end: 0px;
    border-bottom: 1px solid var(--ion-color-light) !important;

    ion-label {
      margin: 5px 8px 5px 0;
    }

    i[slot='start'] {
      color: var(--ion-color-primary);
      width: 35px;
      height: 35px;
      font-size: 1.2rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      margin: 0px 16px 0px 0;
    }
    a {
      padding-left: 0px !important;
    }
  }
}

.is-danger {
  color: var(--cms-error-text-color) !important;
}

.toast-style {
  z-index: 30000 !important;
  position: absolute;
  --button-color: white !important;
}
.ngx-toastr {
  &.notification-toast-style {
    width: 100% !important;
    padding: 15px 50px 15px 20px;
    padding-top: calc(15px + env(safe-area-inset-top));
    color: #292929;
    background-color: #fff;
    .toast-title {
      margin-bottom: 5px;
    }
  }
}

.plt-mobile {
  .desktop-header {
    display: none;
  }

  ion-menu {
    &[side='start'] {
    }

    &[side='end'] {
      display: none;
    }
  }
  ion-split-pane {
    .content-main {
      display: block !important;
      .subheader {
        display: none;
      }
    }
  }
}

body {
  --tabs-height: calc(60px + env(safe-area-inset-bottom));

  .tabbed {
    --padding-bottom: var(--tabs-height) !important;
  }
}

// =========================================
// ========= Desktop layout styles =========
// =========================================

.plt-desktop {
  --header-height: 40px;
  --subheader-height: 40px;

  body {
    background: var(--cms-web-bg-color);
  }

  ion-app {
    margin: 0 auto;
    max-width: 1400px;
  }

  .not-logged {
    ion-split-pane {
      height: 100% !important;
      margin-top: 0px !important;
      ion-menu {
        --max-width: 0px !important;
        max-width: 0px !important;
        display: none !important;
      }

      .content-main {
        display: flex !important;
        border: none !important;
      }

      ion-router-outlet {
        height: 100% !important;
        max-width: 100% !important;
        margin-top: 0px !important;
      }
    }
  }

  .only-desktop {
    display: inherit;
  }

  .only-mobile {
    display: none;
  }

  .desktop-header {
    position: absolute;
    width: 100%;
    height: var(--header-height);
    background-color: var(--cms-header-bg-color);
    border-bottom: 1px solid var(--ion-color-light);
    ion-toolbar {
      --min-height: 40px;
      border-bottom: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
      --background: var(--cms-header-bg-color);

      .desktop-logo {
        height: 30px;
        width: 200px;
        margin-left: 1rem;
        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: contain;
        }
      }

      ion-buttons {
        ion-button,
        ion-menu-button {
          min-height: 0;
          &.notification-bell {
            display: none;
          }
          i,
          ion-icon {
            font-size: 20px;
          }
        }
      }

      .icon-menu {
        display: none;
      }
    }
  }

  ion-split-pane {
    background-color: var(--ion-color-light);
    height: calc(100vh - var(--header-height));
    margin-top: var(--header-height);
    justify-content: space-between;

    ion-menu {
      &[side='start'] {
        max-width: 300px;
        min-width: 300px;
        width: 10vw;
        ion-header {
          height: var(--subheader-height);
          display: none;
        }

        ion-list {
          display: block;
          ion-menu-toggle {
            display: block !important;
          }
        }

        ion-footer {
          display: none;
        }
      }

      &[side='end'] {
        max-width: 400px;
        min-width: 400px;
        width: 10vw;
        flex: 0 1 auto;
        left: unset;
        right: 0;
        position: relative;
        display: none;
        &.menu-pane-visible {
          display: flex;
        }
        ion-header {
          height: var(--subheader-height);
        }
        ion-list {
          display: block;
          ion-menu-toggle {
            display: block !important;
          }
        }
      }

      .notifications-head {
        color: var(--ion-color-dark);
        padding: 10px 10px;
        text-decoration: none;
        font-family: 'poppinssemibold';

        ion-label {
          font-size: 18px;
        }
      }
    }

    .content-main {
      flex: 1 1 100%;
      max-width: unset;
      display: flex !important;

      .subheader {
        width: 100%;
        height: var(--subheader-height);
        background-color: var(--cms-header-bg-color);

        ion-toolbar {
          height: var(--subheader-height);
          --padding-start: 0.5rem;
          --padding-end: 1rem;
          --min-height: 40px;
          ion-buttons {
            margin-inline-start: 0;
            ion-button {
              min-height: 0;
              font-size: 15px;
              --padding-start: 0;
            }
          }

          .breadcrumb {
            color: var(--ion-color-medium);
            padding: 10px 10px;
            text-decoration: none;
            font-family: 'poppinssemibold';
            font-size: 18px;
            &.active {
              pointer-events: none;
              color: var(--ion-color-dark);

              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              width: 32vw;
            }
          }
        }
      }
      ion-router-outlet {
        height: calc(100% - var(--subheader-height));
        margin: 0 auto;
        margin-top: var(--subheader-height);
        ion-header {
          display: none !important;
        }
      }
    }
  }

  ion-modal {
    --width: 100%;
    --height: 100%;

    &::part(content) {
      --background: transparent !important;
    }

    ion-content {
      max-width: 700px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

ion-item[detail='true'],
ion-item-divider[detail='true'] {
  cursor: pointer;
}

.ripple-parent {
  position: relative;
  overflow: hidden;
}

.swiper-pagination {
  color: #fff !important;
  min-height: 24px;
  padding-top: 8px;
}

.action-sheets {
  .remove-btn {
    color: var(--ion-color-danger) !important;
  }
}

.alertModal {
  --width: 90%;
  --border-radius: 15px;
  .toolbar-alert {
    padding: 0 !important;
    .title {
      max-width: 90%;
      text-align: center;
      padding: 5px;
      margin: 0 auto;
      color: var(--cms-header-text-color);
    }
  }
  .footer {
    height: 50px;
    .buttons {
      ion-button {
        width: 50%;
        min-height: 50px;
        margin: 0;
        --border-radius: 0 0 0 4px;
        --background: white;
        --color: var(--ion-color-primary);
        border-top: 1px solid var(--ion-color-light);
        &.rigth {
          --border-radius: 0 0 4px 0;
          border-left: 1px solid var(--ion-color-light);
        }
        &.full-width {
          width: 100%;
        }
      }
    }
  }
}

ion-modal.auto-height {
  &.bottom {
    align-items: flex-end;
  }

  --height: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      max-height: 80vh;
      overflow: auto;
      padding: 10px 15px;
      margin-bottom: 5px;
    }
  }
}

.content-editor {
  ion-label {
    line-height: 1.2;
    justify-content: space-between;
    display: flex;
    margin: inherit;
    font-size: 1rem;
  }
  quill-editor {
    margin-top: 8px;
    .ql-container,
    .ql-editor {
      min-height: 200px !important;
    }
  }
}

.modalSearchPage {
  .modal-wrapper {
    max-width: 600px;
    max-height: 90vh;
    margin: auto;
  }
}

.modal-new-wall-item {
  .modal-wrapper {
    max-width: 600px;
    max-height: 100vh;
    margin: auto;
  }
}

.viewport > div > div:last-child {
  height: 0 !important;
}

.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}

@media (max-width: 1100px) {
  .plt-desktop {
    .desktop-header {
      ion-toolbar {
        .desktop-logo {
          margin: auto;
        }
        ion-buttons {
          ion-button {
            &.notification-bell {
              display: block;
            }
            &.button-profile,
            &.button-logout {
              display: none;
            }
          }
        }
        .icon-menu {
          display: block;
        }
      }
    }

    ion-split-pane {
      ion-menu[side='start'] {
        z-index: 100;
        ion-header {
          height: auto;
          display: block;
        }
      }
    }
    .only-responsive {
      display: inherit;
    }
  }
}
