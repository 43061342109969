@font-face {
  font-family: 'poppinslight';
  src: url('/assets/fonts/poppins-light-webfont.eot');
  src: url('/assets/fonts/poppins-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/poppins-light-webfont.woff2') format('woff2'), url('/assets/fonts/poppins-light-webfont.woff') format('woff'),
    url('/assets/fonts/poppins-light-webfont.ttf') format('truetype'), url('/assets/fonts/poppins-light-webfont.svg#poppinslight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppinsregular';
  src: url('/assets/fonts/poppins-regular-webfont.eot');
  src: url('/assets/fonts/poppins-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/poppins-regular-webfont.woff2') format('woff2'), url('/assets/fonts/poppins-regular-webfont.woff') format('woff'),
    url('/assets/fonts/poppins-regular-webfont.ttf') format('truetype'), url('/assets/fonts/poppins-regular-webfont.svg#poppinsregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppinssemibold';
  src: url('/assets/fonts/poppins-semibold-webfont.eot');
  src: url('/assets/fonts/poppins-semibold-webfont.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/poppins-semibold-webfont.woff2') format('woff2'), url('/assets/fonts/poppins-semibold-webfont.woff') format('woff'),
    url('/assets/fonts/poppins-semibold-webfont.ttf') format('truetype'),
    url('/assets/fonts/poppins-semibold-webfont.svg#poppinssemibold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Empatica-icons';
  src: url('/assets/fonts/empatica-icons.eot?ky6eyk');
  src: url('/assets/fonts/empatica-icons.eot?ky6eyk#iefix') format('embedded-opentype'),
    url('/assets/fonts/empatica-icons.ttf?ky6eyk') format('truetype'), url('/assets/fonts/empatica-icons.woff?ky6eyk') format('woff'),
    url('/assets/fonts/empatica-icons.svg?ky6eyk#empatica-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
