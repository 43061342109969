ion-header {
  color: var(--cms-header-text-color);
  --background: var(--cms-header-bg-color);

  ion-toolbar {
    --background: var(--cms-header-bg-color) !important;
    //--padding-start:1rem;
    //--padding-end:1rem;
    border-width: 0px !important;

    ion-buttons {
      ion-menu-button {
        width: 40px;
        ion-badge {
          right: -3px !important;
          top: 8px !important;
        }
      }
      .button {
        color: var(--cms-header-icon-color);
        --color: var(--cms-header-icon-color);
        --background: transparent !important;
        font-size: 1.15rem;
        position: relative;
        margin: 0px;
        min-width: 32px;

        ion-badge {
          position: absolute;
          top: 1px;
          right: -5px;
          --background: var(--ion-color-danger);
          background: var(--ion-color-danger);
          color: #fff;
          font-family: var(--font-bold);
          padding: 4px 3px 2px;
          font-size: 0.6rem;
          min-width: 16px;
        }

        &[menu] {
          ion-badge {
            right: -10px;
          }
        }
      }

      ion-back-button {
        --font-size: 1rem;
        font-family: var(--font-light);
        font-weight: normal;
        min-width: 30px !important;

        ion-icon {
          color: var(--cms-header-icon-color);
          font-size: 1rem !important;
          margin-right: 5px !important;
          line-height: 1 !important;
          --icon-margin-start: 3px !important;

          &:before {
            content: '\e901';
            font-family: var(--font-icon);
          }
        }
      }
    }

    ion-title {
      text-align: center;
      color: var(--cms-header-text-color);
      font-weight: normal;

      &.title-ios {
        padding-left: unset;
        padding-right: unset;
        padding-inline-start: 110px;
        padding-inline-end: 110px;
        font-size: 16px;
      }

      img {
        max-height: 35px;
        min-height: 30px;
        object-fit: contain;
        object-position: center;
      }
    }

    &.toolbar-segment {
      --padding-start: 0px;
      --padding-end: 0px;
      --padding-bottom: 0px;
      ion-segment {
        ion-segment-button {
          position: relative;
          text-transform: none !important;
          background: var(--cms-button-bg-color);
          height: 3em;
          min-height: 3em;
          --indicator-height: 0px;
          color: var(--cms-button-text-color);
          &.segment-button-checked:after {
            content: '';
            width: 100%;
            height: 4px;
            position: absolute;
            bottom: 0;
            background-color: var(--cms-button-bg-color-pressed);
          }
        }
      }
    }
  }

  &:after {
    height: 0px !important;
  }
}
