ion-button {
  &.ios,
  &.md {
    height: 2.4rem;
    --border-radius: 5px;
    --background: var(--cms-button-bg-color);
    --background-activated: var(--cms-button-bg-color-pressed);
    --background-focused: var(--cms-button-bg-color-pressed);
    --color: var(--cms-button-text-color);
    text-transform: none;
    --box-shadow: none;

    &[clear] {
      --color: var(--cms-button-bg-color);
      --background: transparent;
      padding: 0 0em;
    }

    &.activated {
      background-color: var(--cms-button-bg-color-pressed);
    }
  }

  .plt-desktop & {
    min-height: 56px;
  }
}
