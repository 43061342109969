.calendar-component {
  margin-bottom: 10px;

  &.list-view {
    .events-container {
      position: relative;

      .calendar-separator {
        color: var(--ion-color-dark);
        margin-bottom: 1rem;
        z-index: 10;
        position: relative;
        font-weight: bold;
      }

      ion-item.item {
        padding: 0px;
        //border-bottom: 1px solid var(--ion-color-light);
        //background-color: var(--ion-color-light);
        border-radius: 5px;
        margin-bottom: 16px;
        z-index: 10;
        position: relative;

        &:last-child{
          margin-bottom: 5px;
        }

        .plt-desktop & {
          cursor: pointer;
        }

        ion-label {
          h3 {
            word-wrap: normal;
            white-space: normal;
          }

          small {
            margin-bottom: 10px;
            display: block;
            color: var(--ion-color-dark);
            i {
              font-size: 125%;
              color: var(--ion-color-dark);
              display: inline-flex;
              align-items: center;
              justify-content: center;
              border-radius: 3px;
              margin: 0px 10px 0px 0;
            }
          }

          p {
            word-wrap: normal;
            white-space: normal;
          }
        }

        .item-inner {
          padding-right: 0px;
        }
      }

      // &:after {
      //   height: calc(100% - 50px);
      //   width: 2px;
      //   background: var(--ion-color-light);
      //   left: calc(50% - 1px);
      //   content: '';
      //   position: absolute;
      //   top: 16px;
      //   z-index: 1;
      // }
    }
  }
}
