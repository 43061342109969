ion-alert {
  .alert-wrapper {
    .alert-head {
      .alert-title {
        font-size: 1rem !important;
        line-height: 1.2;
        margin-bottom: 10px;
      }
    }

    .alert-button-group {
      .alert-button {
        font-size: 16px;
        &.danger {
          color: var(--ion-color-danger);
        }
      }
    }
  }
}
