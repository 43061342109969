.card {
  border-radius: 5px;

  &.login-card {
    background: #fff;
    width: 100%;

    .plt-desktop & {
      max-width: 650px;
    }

    .logo {
      height: 9vh;
      max-width: 220px;
      object-fit: contain;
      object-position: center center;
      max-width: 100%;
    }
  }
}

.advice-row {
  .big-icon {
    width: 25vw;
    //color: map-get($colors, medium );

    .plt-desktop & {
      width: 250px;
    }
  }

  h2 {
    font-family: var(--font-bold);
    font-size: 2rem !important;
  }
}

ion-badge {
  &.ios,
  &.md {
    background: transparent;
    font-family: var(--font-regular);
    --color: var(--cms-component-header-text-color) !important;
    font-weight: normal;
  }
}

ion-list.list-md,
ion-list.list-ios,
ion-grid {
  margin-bottom: 0px;
  margin-top: 0px;

  &.buttons-view {
    padding-top: 0px;
    padding-bottom: 0px;

    ion-item.item {
      --background: var(--cms-component-header-bg-color) !important;
      --detail-icon-opacity: 0;
      --inner-padding-end: 0px;
      --min-height: 64px;

      ion-label {
        --color: var(--cms-component-header-text-color) !important;
        margin-top: 1.2rem;
        margin-bottom: 1.2rem;
        font-size: 1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      i[slot='start'] {
        color: var(--cms-component-header-button-icon-color, --cms-component-header-icon-color);
        width: 35px;
        height: 35px;
        font-size: 2rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0px 16px 0px 0;
      }

      ion-icon[slot='end'] {
        margin-left: 13px;
        color: var(--cms-component-header-text-color) !important;
        font-size: 1.2rem;
        margin-right: -5px;
      }

      ion-badge {
        &.ios,
        &.md {
          margin: 0px;
          padding: 0px;
          --color: var(--cms-component-header-text-color) !important;
          opacity: 1;
        }
      }
    }
  }

  &.list-view {
    padding-top: 0px;
    padding-bottom: 0px;

    ion-item-divider.item {
      --padding-end: 0px;
      --padding-start: 16px;

      border-bottom: 0px solid var(--ion-color-light) !important;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      background-color: var(--cms-component-header-bg-color) !important;

      .plt-desktop & {
        cursor: pointer;
      }

      ion-label {
        text-transform: none;
        --color: var(--cms-component-header-text-color) !important;
        font-size: 1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-top: 1.2rem;
        margin-bottom: 1.2rem;
      }

      ion-badge {
        &.ios,
        &.md {
          margin: 0px;
          padding: 0px;
          --color: var(--cms-component-header-text-color) !important;
          opacity: 1;
          border-radius: 0px;
        }
      }

      ion-icon[slot='end'] {
        margin-left: 10px;
        color: var(--cms-component-header-text-color) !important;
        font-size: 1.2rem;
      }
    }

    ion-item.item {
      ion-label {
        overflow: hidden;
        text-overflow: ellipsis;
        //color: var(--cms-link-text-color);
        font-size: 1rem;
      }

      &.image-item {
        --inner-padding-start: 0px;
        --inner-padding-end: 0px;
        --padding-start: 0px;
        --padding-end: 0px;
        cursor: pointer;

        img {
          // min-height: 250px;
          width: 100%;
          background-color: white;
          display: block;
          object-fit: contain;
          object-position: center;
          opacity: 1;
          transition: all ease 0.3s;
        }

        &:hover {
          img {
            opacity: 0.8;
          }
        }
      }

      &.thumbnail-list {
        --inner-padding-start: 0px;
        --inner-padding-end: 0px;
        --padding-start: 1rem;
        --padding-end: 1rem;
        cursor: pointer;
        display: flex;
        align-items: flex-start;
          margin-top: 1rem;
          margin-bottom: 1rem;
          padding-bottom: 1rem;
          border-bottom: 1px solid var(--ion-color-light);
       

        .thumb{
          max-width: 100px;
          aspect-ratio: 1/1;
          border-radius: 15px;
          overflow: hidden;

          image-loader{
            display: block;
            height: 100%;
            width: 100%;
          }

          ion-icon{
            height: 25px;
            width: 24px;
            top: calc(50% - 25px);
            left: calc(50% - 25px);
            opacity: 1;
          }
          img {
            // min-height: 250px;
            width: 100%;
            height: 100%;
            background-color: white;
            display: block;
            object-fit: cover;
            object-position: center;
            opacity: 1;
            transition: all ease 0.3s;
          }
  
        }
        ion-label{
          .content{
            padding: 5px 0px 0px !important;

            h3{
              line-height: 1.1 !important;
              font-size: 1rem !important;
              word-wrap: normal;
              white-space: normal;
            }

            p{
              word-wrap: normal;
              color: var(--ion-color-medium) !important;
              line-height: 1.2 !important;
              font-size: 0.8rem !important;
            }
          }
        }

        

  
        &:hover {
          img {
            opacity: 0.8;
          }
        }
      }

      ion-badge {
        &.ios,
        &.md {
          margin: 0px;
          padding: 0px;
          --color: var(--cms-component-header-text-color) !important;
          opacity: 1;
        }
      }
    }
  }

  &.carousel-view {
    padding: 0;
    margin-bottom: 0px;

    ion-item-divider.item {
      --padding-start: 16px;
      --padding-end: 0;
      border-bottom: 0px solid var(--ion-color-light) !important;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      background-color: var(--cms-component-header-bg-color) !important;

      ion-label {
        text-transform: none;
        --color: var(--cms-component-header-text-color) !important;
        font-size: 1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-top: 1.2rem;
        margin-bottom: 1.2rem;
      }

      ion-badge {
        &.ios,
        &.md {
          margin: 0px;
          padding: 0px;
          --color: var(--cms-component-header-text-color) !important;
          opacity: 1;
        }
      }

      ion-icon[slot='end'] {
        margin-left: 10px;
        color: var(--cms-component-header-text-color) !important;
        font-size: 1.2rem;
      }
    }

    swiper-container {
      height: 33vh;

      .plt-desktop & {
        height: initial;
      }

      :host-context(.plt-desktop) {
        height: initial !important;
      }

      swiper-slide {
        // TODO
        //align-self: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }

        &.activated {
          opacity: 0.9;
        }

        &.is-video {
          .icon-play {
            background-color: white;
            border-radius: 50%;
            height: 50px;
            width: 50px;
            top: calc(50% - 35px);
            padding: 10px 7px 10px 13px;
            left: calc(50% - 35px);
            position: absolute;
            box-shadow: 0px 0px 10px -2px var(--ion-color-dark);
            color: var(--ion-color-dark);
          }
        }
      }

      .swiper-pagination {
        .swiper-pagination-bullet {
          background: var(--ion-color-light);
          width: 10px;
          height: 10px;
          opacity: 0.5;
          box-shadow: 0px 0px 5px #000;

          &.swiper-pagination-bullet-active {
            opacity: 1;
          }
        }
      }
    }
  }

  &.space-after {
    margin-bottom: 1px;
  }
}

quill-editor {
  width: 100%;
  height: auto;
  min-height: 200px;
  display: block;
}

ion-item.item {
  --detail-icon-color: var(--ion-color-dark);

  &.ios,
  &.md {
    //--padding-start: 0px;

    &.see-more {
      border-bottom: 1px solid var(--ion-color-light) !important;
      border-top: 1px solid var(--ion-color-light) !important;
      margin-top: -1px;

      ion-label {
        font-family: var(--font-regular);
        text-decoration: none !important;
        color: var(--ion-color-dark) !important;
      }

      ion-badge {
        &.ios,
        &.md {
          margin: 0px;
          padding: 0px;
          --color: var(--cms-component-header-text-color) !important;
          opacity: 1;
        }
      }
    }

    ion-avatar {
      width: 50px;
      height: 50px;
      max-width: 50px;
      max-height: 50px;
      min-width: 50px;
      min-height: 50px;
      background-color: var(--ion-color-light) !important;
      border-radius: 50px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    ion-thumbnail {
      width: 50px;
      height: 50px;
      max-width: 50px;
      max-height: 50px;
      min-width: 50px;
      min-height: 50px;
      --background: var(--ion-color-light) !important;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &.item-input {
      --padding-start: 0px;
      --padding-end: 0px;
      --inner-padding-start: 0px;
      --inner-padding-end: 0px;
      margin-bottom: 1rem;

      ion-label {
        line-height: 1.2;
        justify-content: space-between;
        display: flex;
        margin: inherit;
        font-size: 1rem;

        [slot='end'] {
          --color: var(--ion-color-primary);
          position: relative;
          z-index: 99;
        }
      }

      ion-input,
      ion-select, ionic-selectable {
        border: 1px solid var(--ion-color-light);
        border-radius: 5px;
        height: 2.3em;
        font-size: 1rem;
        --padding-end: 1rem;
        --padding-start: 1rem;
        margin-top: 5px;
      }

      ionic-selectable{
        padding-left: 1rem;
        padding-right: 1rem;
      }

      .datetime-input {
        border: 1px solid var(--ion-color-light);
        border-radius: 5px;
        height: 2.3em;
        font-size: 1rem;
        padding: 7px 16px;
        margin-top: 5px;
        width: 100%;
      }
    }

    &.item-textarea {
      padding-left: 0px;

      ion-label {
        line-height: 1.2;
      }

      ion-textarea {
        border: 1px solid var(--ion-color-light);
        border-radius: 5px;
        min-height: 2.3em;
        padding: 2px 15px;
      }
    }

    &.item-line {
      ion-input {
      }
    }

    &.item-checkbox,
    &.item-radio {
      --padding-top: 0rem;
      --padding-bottom: 0rem;
      --padding-end: 1rem;
      --padding-start: 1rem;
      --border-width: 1px;
      --border-color: var(--ion-color-light);
      --border-radius: 5px;
      border-radius: 5px;
      margin-bottom: 1rem;
      margin-top: 1rem;

      .plt-desktop & {
        margin-bottom: 0.5rem;
        margin-top: 0rem;
      }

      ion-label {
        font-size: 1rem;
        margin: 5px 16px 5px 2px;
        white-space: normal;
        line-height: 1.2;
      }

      ion-checkbox,
      ion-radio {
      }
    }

    &.item-checkbox {
      ion-checkbox {
        .checkbox-icon {
          border-radius: 5px;
          border: 1px solid var(--ion-color-light);
          width: 21px;
          height: 21px;
          -webkit-transition-duration: 280ms;
          transition-duration: 280ms;
          -webkit-transition-property: background;
          transition-property: background;
          -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

          &.checkbox-checked {
            border-color: var(--cms-button-bg-color);
            background-color: var(--cms-button-bg-color);

            .checkbox-inner {
              left: 6px;
              top: 2px;
              width: 6px;
              height: 12px;
            }
          }
        }
      }
    }

    &.item-radio {
      .item-inner {
        ion-radio {
          order: 1;

          .radio-icon {
            width: 21px;
            height: 21px;
            border-radius: 50%;
            border: 1px solid var(--ion-color-light);
            -webkit-transition-duration: 280ms;
            transition-duration: 280ms;
            -webkit-transition-property: background;
            transition-property: background;
            -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

            &.radio-checked {
              border-color: var(--cms-button-bg-color);
              background-color: var(--cms-button-bg-color);

              .radio-inner {
                border: 2px solid #fff;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                left: 2px;
                top: 2px;
              }
            }
          }
        }

        .input-wrapper {
          order: 2;
        }
      }
    }
  }

  &.activated {
    background-color: var(--ion-color-light);
  }
}

ion-searchbar {
  padding: 10px 16px !important;
  --background: white !important;
  --icon-color: var(--ion-color-dark) !important;
  min-height: 44px;
  --box-shadow: none;

  .searchbar-input-container {
    border: 1px solid var(--ion-color-light);
    border-radius: 5px;
    padding: 2px 0px;

    input {
      box-shadow: none !important;
      padding: 0 28px;
      -webkit-padding-start: 40px !important;
      padding-inline-start: 40px !important;
      -webkit-padding-end: 55px;
      padding-inline-end: 55px;
      margin: 0 !important;
    }

    .searchbar-search-icon {
      width: 23px;
      height: 23px;
      top: 6px;
      left: 7px;

      &.md {
        top: 11px;
      }
    }
  }
}

.bottom-toolbar-container {
  padding: var(--small-item-padding) !important;

  ion-label {
    margin: 0 !important;
  }

  .bottom-toolbar {
    display: flex;
    justify-content: flex-end;
    font-size: 1rem;
    div{
      margin-left: 1em;
    }
    div{
      display: flex;
    }
  }

  ion-icon {
    font-size: 24px;
    
    &.filled-heart{
      color: #ed4956;
    }
  }
}

.error-msg {
  margin: 5px 0 5px 5px;
  color: var(--ion-color-danger);
  animation: fadeError 0.3s 1;
}

@keyframes fadeError {
  from {
    opacity: 0;
    max-height: 0;
  }

  to {
    opacity: 1;
    max-height: 100px;
  }
}
