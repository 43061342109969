ion-menu {
  .menu-bg {
    position: absolute;
    background-color: var(--cms-menu-bg-color);
    background-image: var(--cms-menu-bg-image);
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
  }

  ion-header {
    color: var(--cms-menu-text-color) !important;
    background: transparent !important;
    border-bottom: 1px solid rgba(var(--ion-color-dark-rgb), 0.2);
    padding-top: calc(16px + (env(safe-area-inset-top) / 2)) !important;
    .header-background {
      backdrop-filter: saturate(100%) blur(0px);
    }
    &:after {
      background-image: none;
    }

    ion-toolbar {
      .toolbar-background {
        --background: transparent;
      }
    }

    ion-item.item {
      --background: transparent;
      --color: var(--cms-menu-text-color);
      --padding-start: 0px;

      ion-label {
        --color: var(--cms-menu-text-color) !important;
        margin: 0;
        h2 {
          margin-top: 0px !important;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: normal;
          color: var(--cms-menu-text-color) !important;
        }
        h3 {
          font-size: 1rem !important;
          text-align: right;
        }
        p.menu-user-email {
          margin-bottom: 5px !important;
          color: var(--cms-menu-text-color) !important;
          font-size: 0.9rem !important;
          text-align: right;
        }
        p.menu-buttons {
          color: var(--cms-menu-text-color) !important;
          font-weight: bold !important;
          text-align: right;
          a {
            color: var(--cms-menu-text-color) !important;
            outline: none;
            font-size: 0.9rem;
            margin: 0 !important;
            text-decoration: none;
          }
        }
      }

      &.activated {
        background-color: transparent;
        opacity: 0.6;
      }
    }
  }

  ion-content {
    --background: transparent;
    --offset-bottom: 2px !important;
    --offset-top: 0px !important;

    ion-list {
      margin-bottom: 0px;
      background-color: transparent !important;
      //border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      padding: 5px;

      ion-item {
        --background: transparent;
        color: var(--cms-menu-text-color);
        min-height: 2.2rem;

        ion-label {
          margin: 5px 0px 5px 0;
          line-height: 1.2;
        }

        [slot='start'] {
          color: rgb(48, 49, 51);
        }

        ion-badge {
          --background: var(--ion-color-danger);
          color: var(--cms-menu-icon-color) !important;
          font-family: var(--font-bold);
          padding: 4px 4px 2px;
          font-size: 1rem;
        }

        &.activated {
          background-color: transparent;
          opacity: 0.6;
        }
      }

      ion-list {
        --padding-start: 1rem;
      }

      &.default-menu {
        background-color: #fff !important;
        padding: 0;

        ion-item.item {
          --background: transparent;
          --color: var(--cms-menu-text-color);
          min-height: 2.2rem;

          ion-label {
            margin: 5px 0px 5px 0;
            --color: rgb(48, 49, 51) !important;
            color: rgb(48, 49, 51) !important;
          }

          ion-badge {
            --background: var(--ion-color-danger);
            background: var(--ion-color-danger);
            font-family: var(--font-bold);
            padding: 4px 4px 2px;
            font-size: 0.7rem;
            min-width: 16px;
            color: #fff !important;
          }
          &.activated {
            background-color: transparent;
            opacity: 0.6;
          }
        }

        ion-item-divider.item {
          background-color: transparent !important;

          border-bottom: none !important;

          ion-label {
            --color: var(--cms-menu-text-color) !important;
            margin: 0 !important;
            font-size: 1rem;
          }
        }
      }

      &.menu-list {
        ion-item-group {
          margin-bottom: 0.4rem;
        }
        ion-item {
          --background: transparent;
          --color: var(--cms-menu-text-color);
          min-height: 2.2rem;
          ion-label {
            margin: 5px 0px 5px 0;
            --color: var(--cms-menu-text-color) !important;
            color: var(--cms-menu-text-color) !important;
          }

          ion-badge {
            --background: var(--ion-color-danger);
          }
          &.activated {
            background-color: transparent;
            opacity: 0.6;
          }
        }

        ion-item-divider.item {
          background-color: transparent !important;
          min-height: 2.2rem;
          border-bottom: none !important;

          ion-label {
            --color: var(--cms-menu-text-color) !important;
            margin: 0 !important;
            font-size: 1rem;
          }

          .plt-desktop & {
            cursor: pointer;
            transition: all ease 0.3s;
            &:hover {
              background-color: var(--cms-link-text-color) !important;

              ion-label {
                --color: var(--cms-menu-hover-color) !important;
              }
            }
          }
        }

        .submenu {
          padding-top: 0;
          ion-item.item {
            --min-height: 40px;
            //padding-left: 1rem;

            .plt-desktop & {
              cursor: pointer;
              transition: all ease 0.3s;
              &:hover {
                --background: var(--cms-link-text-color);
                ion-label {
                  color: var(--cms-menu-hover-color) !important;
                }
              }
            }
          }
          ion-label {
            margin: 0 !important;
            padding-left: 2em;
            font-size: 0.9rem;
          }

          ul {
            margin-block-start: 5px;
            margin-block-end: 5px;
          }
        }
      }
    }
  }

  ion-footer {
    --padding-top: 5px;
    --padding-bottom: 5px;
    --ion-padding: 10px;
    .footer-background {
      backdrop-filter: saturate(100%) blur(3px);
    }
    ion-item {
      --background: transparent;
      color: var(--cms-menu-text-color);
      padding: 0px 0px;
      min-height: 2rem;
      ion-label {
        margin: 0px 0px 0px 0;
      }

      [slot='start'] {
        font-size: 1.6rem;
        margin: 0px 15px 0px 0px;
      }
    }
  }
}
