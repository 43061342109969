[class^='icon-empatica'],
[class*=' icon-empatica'],
[class^='file'],
[class*=' file'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Empatica-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-empatica-play:before,
.file-video-icon:before {
  content: "\e914";
}
.icon-empatica-table:before,
.file-spreadsheet-icon:before {
  content: "\e919";
}
.icon-empatica-pin:before {
  content: "\e900";
}
.icon-empatica-back:before {
  content: "\e901";
}
.icon-empatica-bell:before {
  content: "\e902";
}
.icon-empatica-book:before {
  content: "\e903";
}
.icon-empatica-calendar:before {
  content: "\e904";
}
.icon-empatica-check:before {
  content: "\e905";
}
.icon-empatica-close:before {
  content: "\e906";
}
.icon-empatica-envelope:before {
  content: "\e907";
}
.icon-empatica-file:before,
.file-textsheet-icon:before {
  content: "\e908";
}
.icon-empatica-filter:before {
  content: "\e909";
}
.icon-empatica-folder:before {
  content: "\e90a";
}
.icon-empatica-grid:before {
  content: "\e90b";
}
.icon-empatica-heart:before {
  content: "\e90c";
}
.icon-empatica-link:before {
  content: "\e90d";
}
.icon-empatica-logout:before {
  content: "\e90e";
}
.icon-empatica-map-marker:before {
  content: "\e90f";
}
.icon-empatica-menu:before {
  content: "\e910";
}
.icon-empatica-message:before {
  content: "\e911";
}
.icon-empatica-phone:before {
  content: "\e912";
}
.icon-empatica-picture:before,
.file-image-icon:before {
  content: "\e913";
}
.icon-empatica-plus:before {
  content: "\e915";
}
.icon-empatica-presentation:before,
.file-presentation-icon:before {
  content: "\e916";
}
.icon-empatica-right:before {
  content: "\e917";
}
.icon-empatica-share:before {
  content: "\e918";
}
.icon-empatica-users:before {
  content: "\e91a";
}
.icon-empatica-notes:before {
  content: "\e91b";
}
.icon-empatica-searchfile:before {
  content: "\e91c";
}
.icon-empatica-heart-outline:before {
  content: "\e91d";
}
.icon-empatica-download:before {
  content: "\e91e";
}
.icon-empatica-edit:before {
  content: "\e91f";
}
.icon-empatica-trash:before {
  content: "\e920";
}
.icon-empatica-back-time:before {
  content: "\e921";
}
.icon-empatica-run-time:before {
  content: "\e926";
}